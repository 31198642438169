import { ErrorEvent } from '@sentry/types/types/event';
import { gameDataService } from 'services';


const errors = [
    'user.already.has.this.activity',
];


export const beforeSend = (event: ErrorEvent) => {
    const isErrorExcluded = errors.some(error => event.message && event.message.includes(error));

    if (isErrorExcluded) return null;

    const userId = gameDataService.userId;
    const gameId = gameDataService.gameId;

    if (userId) {
        event.user = {
            ...event.user,
            id: userId,
            gameId,
        };
    }

    return event;
};
