import * as Sentry from '@sentry/react';
import { BrowserStorageAbstract } from './browser-storage.abstract';


export class BrowserStorageLocal extends BrowserStorageAbstract {
    constructor () {
        super(window.localStorage, Sentry);
        this.storageType = 'local';
    }
}
