import axios, { AxiosResponse } from 'axios';
import { gameDataService } from './GameDataService';


interface ICredentials {
    URL: string;
}

axios.interceptors.response.use(
    (res) => res,
    (error) => {
        try {
            const response     = error.response;
            const status       = response && response.status;
            const data         = response && response.data;
            const errorMessage = data && data.detail;

            if (status === 401 && errorMessage === 'Invalid token.') {
                // TODO: dispach logOut action

                return Promise.reject(new Error('Token has expired'));
            }

            return Promise.reject(error);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('🚀 ~ file: BaseService.ts ~ line 41 ~ e', e);
        }
    }
);

export class BaseService {
    protected _credentials?: ICredentials;

    protected _prefix: string = '';

    public setCredentials (credentials: ICredentials): void {
        this._credentials = credentials;
    }

    protected getCurrentUrl (path: string): string {
        if (this.credentials) {
            return `${ this.credentials.URL }${ this.prefix }${ path }`;
        }

        return `${ this.prefix }${ path }`;
    }

    protected get credentials () {
        return this._credentials;
    }

    protected get headers (): { headers: any } {
        const gameToken = gameDataService.gameToken;

        return {
            headers: {
                Authorization: gameToken ? `Bearer ${ gameToken }` : '',
            },
        };
    }

    public set prefix (prefix: string | undefined) {
        this._prefix = prefix || '';
    }

    public get prefix (): string | undefined {
        return this._prefix;
    }

    public async get <T> (route: string, params?: any): Promise<AxiosResponse<T>> {
        const url: string = this.getCurrentUrl(route);
        return await axios.get(url, { ...this.headers, params });
    }

    public async post <T> (route: string, data: any, customHeaders = {}): Promise<AxiosResponse<T>> {
        const url: string = this.getCurrentUrl(route);
        return await axios.post(url, data, {
            headers: { ...this.headers.headers, ...customHeaders },
        });
    }

    public async put (route: string, data: any) {
        const url: string = this.getCurrentUrl(route);
        return await axios.put(url, data, this.headers);
    }

    public async patch (route: string, data: any) {
        const url: string = this.getCurrentUrl(route);
        return await axios.patch(url, data, this.headers);
    }

    public async remove (route: string) {
        const url: string = this.getCurrentUrl(route);
        return await axios.delete(url, this.headers);
    }
}
