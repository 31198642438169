/* eslint-disable no-unused-vars */
import { browserCookieStorage, browserLocalStorage, browserSessionStorage, BrowserStorageAbstract } from 'entities/browser-storage';
import { parseJwt } from 'shared/lib/parse-jwt';


export class GameDataService {
    constructor (
        private readonly _sessionStorage: BrowserStorageAbstract,
        private readonly _localStorage: BrowserStorageAbstract,
        private readonly _cookieStorage: BrowserStorageAbstract
    ) {}

    saveGameToken (passedGameToken?: string | null) {
        if (passedGameToken) {
            this.setItem('game_token', passedGameToken);
            return this.gameToken;
        }

        const gameToken = this.getGameToken();

        if (!gameToken) return this.gameToken;

        this.setItem('game_token', gameToken);

        return this.gameToken;
    }

    saveWebToken () {
        const webToken = this.getWebToken();

        if (!webToken) return this.webToken;

        this.setItem('web_token', webToken);

        return this.webToken;
    }

    saveRedirectUrl (passedRedirectUrl?: string | null) {
        if (passedRedirectUrl) {
            this.setItem('redirect_url', passedRedirectUrl);
            return this.redirectUrl;
        }

        const redirectUrl = this.getRedirectUrl();

        if (!redirectUrl) return this.redirectUrl;

        this.setItem('redirect_url', redirectUrl);

        return this.redirectUrl;
    }

    removeRedirectUrl () {
        this.removeItem('redirect_url');
    }

    saveUserId () {
        const userId = this.getUserId();

        if (!userId) return this.userId;

        this.setItem('user_id', userId);

        return this.userId;
    }

    saveCity () {
        const city = this.getCity();

        if (!city) return this.city;

        this.setItem('city', city);

        return this.city;
    }

    saveGameId (passedGameId?: string | null) {
        if (passedGameId) {
            this.setItem('game_id', passedGameId);
            return this.gameId;
        }

        const gameId = this.getGameId();

        if (!gameId) return this.gameId;

        this.setItem('game_id', gameId);

        return this.gameId;
    }

    saveTSAHash () {
        const TSAHash = this.getTSAHash();

        if (!TSAHash) return this.tsaHash;

        this.setItem('tsahash', TSAHash);

        return this.tsaHash;
    }

    saveSessionToken () {
        const sessionToken = this.getSessionToken();

        if (!sessionToken) return this.sessionToken;

        this.setItem('session_token', sessionToken);

        return this.sessionToken;
    }

    get sessionToken () {
        return this.getItem<string>('session_token') || '';
    }

    get tsaHash () {
        return this.getItem<string>('tsahash') || '';
    }

    get gameToken () {
        return this.getItem<string>('game_token') || '';
    }

    get webToken () {
        return this.getItem<string>('web_token') || '';
    }

    get redirectUrl () {
        return this.getItem<string>('redirect_url') || '';
    }

    get userId () {
        return this.getItem<string>('user_id') || '';
    }

    get city () {
        return this.getItem<string>('city') || '';
    }

    get fromActivity () {
        return this.getFromActivity() || '';
    }

    get gameId () {
        return this.getItem<string>('game_id') || '';
    }

    private getSessionToken () {
        return this.params.get('session_token');
    }

    private getTSAHash () {
        return this.params.get('tsa_hash');
    }

    private getGameId () {
        const gameIdMatch = window.location.pathname.match(/\/([a-z0-9]{24})/);
        return gameIdMatch && gameIdMatch[1] || '';
    }

    private getFromActivity () {
        return this.params.get('from_activity');
    }

    private getCity () {
        return this.params.get('city');
    }

    private getUserId () {
        const gameToken = this.getGameToken();
        const { gamer } = gameToken && parseJwt(gameToken) || {};
        return gamer?.user_id;
    }

    private getRedirectUrl () {
        return this.params.get('redirect_url');
    }

    private getGameToken () {
        return this.params.get('game_token');
    }

    private getWebToken () {
        return this.params.get('web_token')?.replace(/ /g, '+');
    }

    private get params () {
        return new URLSearchParams(window.location.search);
    }

    private setItem (key: string, value: string) {
        this._sessionStorage.setItem(key, value);
        this._localStorage.setItem(key, value);
        this._cookieStorage.setItem(key, value);
    }

    private removeItem (key: string) {
        this._sessionStorage.removeItem(key);
        this._localStorage.removeItem(key);
        this._cookieStorage.removeItem(key);
    }

    private getItem<T> (key: string): T | null {
        return (
            this._sessionStorage.getItem(key) ||
            this._localStorage.getItem(key) ||
            this._cookieStorage.getItem(key)
        );
    }
}


export const gameDataService = new GameDataService(browserSessionStorage, browserLocalStorage, browserCookieStorage);
