import * as Sentry from '@sentry/react';
import { BrowserStorageAbstract } from './browser-storage.abstract';


export class BrowserStorageSession extends BrowserStorageAbstract {
    constructor () {
        super(window.sessionStorage, Sentry);
        this.storageType = 'session';
    }
}
