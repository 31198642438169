import { BrowserCookieStorage } from './browser-storage.cookie';
import { BrowserStorageLocal } from './browser-storage.local';
import { BrowserStorageSession } from './browser-storage.session';


export const browserSessionStorage = new BrowserStorageSession();
export const browserLocalStorage = new BrowserStorageLocal();
export const browserCookieStorage = new BrowserCookieStorage();

export * from './browser-storage.abstract';
