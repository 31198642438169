/* eslint-disable no-unused-vars */
declare global {
    interface Window {
        TelegramGameProxy: {
            receiveEvent: (event: string, data: any) => void;
        };
    }
}

if (window.TelegramGameProxy) {
    window.TelegramGameProxy.receiveEvent('custom_event', { data: 'test' });
} else {
    window.TelegramGameProxy = {
        receiveEvent: () => {},
    };
}

export {};
