import { BaseService } from './BaseService';
import { gameDataService } from './GameDataService';


class AuthAPIService extends BaseService {
    public saveToken (gameId: string, token: string, redirectUrl?: string) {
        gameDataService.saveGameId(gameId);
        gameDataService.saveGameToken(token);
        gameDataService.saveRedirectUrl(redirectUrl);
    }

    public saveOnlyTokenID (gameId: string, token: string) {
        gameDataService.saveGameId(gameId);
        gameDataService.saveGameToken(token);
    }

    public clearGameData () {
        gameDataService.removeRedirectUrl();
    }
}

export const AuthService = new AuthAPIService();
